.brand {
    display: inline-block;
    height: 40px;
    outline: none;

    .brand-image {
        margin-right: 5px;
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 50%;
        width: 31px;
        height: 31px;
    }
    .brand-title {
        font-family: 'SF Pro Text Regular';
        font-weight: lighter;
        font-size: 14px;
    }
}