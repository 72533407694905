.edit-profile-body {
    width: 82%;
    margin: 0 auto;
        
    .change-photo-div {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 30px;
        border-bottom: 1px solid #97979716;
        
        .change-profile-button {
            font-family: 'SF Pro Medium', sans-serif;
            font-size: 13px;
            background: linear-gradient(79deg, rgba(251,187,93,1) 0%, rgba(252,88,153,1) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .image-div {
            margin-bottom: 5px;
            
            .profile-photo {
                background: url("/new-assets/images/chosen-photo-photo.svg");

                width: 106px;
                height: 106px;
                border-radius: 50%;
                box-shadow: 0 5px 25px 0 rgb(0 0 0 / 18%)
            }
        }
        .file-input {
            display: none;
        }
    }
    .input-grid {
        display: grid;
        grid-template-columns: 33% 67%;
        grid-template-rows: 46px 46px 46px 46px;
        text-align: left;
        padding-bottom: 3px;
        border-bottom: 1px solid #97979716;
        margin-bottom: 28px;
        font-size: 13px;
        .input-title {
            font-family: 'SF Pro Light', sans-serif;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            // margin-top: 17px;
        }
        .input {
            font-family: 'SF Pro Light', sans-serif;
            border: none;
            padding: 1px 0 0 10px;
            // padding-top: 10px;
            border-radius: unset;
            border-bottom: 1px solid #97979716;
        }
        .input::placeholder {
            font-family: 'SF Pro Light', sans-serif;
            opacity: 1;
            color: #9D9D9D;
        }
        .last {
            border: none;
        }
    }
}