.post-container {
    background: white;

    // .post-header {
    //     display: grid;
    //     grid-template-columns: 40px calc(44.5vw - 40px) auto;
    //     align-items: center;
    //     margin-top: 10px;
    //     padding-left: 3vw;

        .user-small-image {
            position: relative;
            border-radius: 50%;
            height: 35px;
            
        }
    //     .post-author-name {
    //         font-weight: 500;
    //         font-size: 18px;
    //     }
    // }
    button {
        background: none;
        outline: none;
    }
    .post-picture {
        margin: 1.8vh 5vw 1.1vh;
        width: 90vw;
        height: 90vw;
        border-radius: 10px;
        
        .post-image {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
        .brand-logo {
            position: relative;
            left: 10px;
            top: -50px;
            color: white;
            background: rgba(#000000, 0.2);
            border: 0;
            border-radius: 20px;
            backdrop-filter: blur(20px);
            padding: 0 6px;
            padding-right: 21px;
        }
    }
    .post-info {


        .post-tags-and-values {
            display: grid;
            height: 40px;
            grid-template-columns: 23vw 77vw;

            .post-values {
                border: none;

                .small-image {
                    height: 30%;
                    margin-right: 3px;
                }
                strong {  
                    font-size: 11px;
                    font-family: 'SF Pro Light';
                    opacity: 0.5;
                }
            }
            .tags-scroll {
                display: grid;
                grid-auto-columns: minmax(min-content, max-content);
                align-items: center;
                grid-gap: 14px;
                max-width: 71vw;
                overflow-x: auto;
                overflow-y: hidden;
                justify-items: left;
            }
            .tags-scroll::-webkit-scrollbar{
                display: none;
            }
        }
    }
    .post-description {
        padding: 5px 5vw;
        padding-bottom: 15px;
        font-size: 13px;
        font-family: 'SF Pro Light';
        font-weight: 100;
    } 
    .post-buttons {
        display: grid;
        margin-left: 5vw;
        width: 90vw;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px;
        padding-bottom: 9px;
        border-bottom: 1px solid #97979716;
        justify-content: space-between;
        .post-button {
            display: inline-flex;
            color: #9d9d9d;
            border: none;
            justify-content: center;
            font-size: 11px;
            font-family: 'SF Pro Light';
            padding: 0;
            
            .post-button-image {
                height: 71%;
                margin-right: 7px;
            }
            .wishlist-button-image {
                height: 75%;
                margin-right: 7px;
                opacity: 0.6;
            }
        }
    }
    .post-comments {
        padding-left: 5vw;
        margin-top: 8px;
        font-size: 11px;

        .n-of-posts {
            font-family: 'SF Pro Light';
            height: 15px;
            opacity: 0.5;
            border: none;
        }
        .main-comment {
            padding-left: 7px;
            font-size: 13px;
            font-family: 'Helvetica';
            font-weight: 100;

            strong {
                font-weight: 500;
            }
        }
        .user-small-image {
            border-radius: 50%;
            height: 30px;
            margin-left: 2px;
            margin-bottom: 4px;
        }
        .comment-input {
            display: inline-block;
            width: 79vw;
            min-height: 37px;
            margin-left: 10px;
            padding-left: 15px;
            padding-top: 9px;
            border: 1px solid #97979716;
            outline: none;
            border-radius: 20px;
        }
        .comment-input[contenteditable]:empty::before {
            content: "Add a comment";
            font-family: 'SF Pro Light';
            color: #9d9d9d;
          }
    }
}