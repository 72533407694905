@font-face {
    font-family: 'SF Pro Text Regular'; 
    src: url(./fonts/SF-Pro-Text-Regular.woff2),
         url(./fonts/SF-Pro-Text-Regular.eot),
         url(./fonts/SF-Pro-Text-Regular.ttf),
         url(./fonts/SF-Pro-Text-Regular.woff); 
         font-weight: normal;
}
@font-face {
    font-family: 'SF Pro Light'; 
    src: url(./fonts/SF-Pro-Text-Light.woff2),
         url(./fonts/SF-Pro-Text-Light.eot),
         url(./fonts/SF-Pro-Text-Light.ttf),
         url(./fonts/SF-Pro-Text-Light.woff); 
         font-weight: normal;
}
@font-face {
    font-family: 'SF Pro Medium'; 
    src: url(./fonts/SF-Pro-Text-Medium.woff2),
         url(./fonts/SF-Pro-Text-Medium.eot),
         url(./fonts/SF-Pro-Text-Medium.ttf),
         url(./fonts/SF-Pro-Text-Medium.woff); 
         font-weight: normal;
}