.gradient {
    background: linear-gradient(241deg, #FC5899 0%, #FBBB5D 100%);
    border-radius: 50%;
    width: 24.5px;
    height: 24.5px;
    display: flex;
    justify-content: center;
    align-items: center;    

    .user-small-image {
        border-radius: 50%;
        max-height: 22.5px;
    }
    .no-user-small-image {
        max-height: 30px;
    }
}