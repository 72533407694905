.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
    background: white;
   }
.edit-modal {
    position: absolute;
    z-index: 100;
   
    width: 100vw;
    height: 100vw;
    text-align: center;
    .edit-modal-body {
        display: flex;
        flex-direction: column;
        font-family: 'SF Pro Light', sans-serif;
        font-size: 13px;
        text-align: left;
        margin: 0 13vw;
        
        .modal-input {
            border-radius: unset;
            border: none;
            outline: none;
            caret-color: #fc6292;
            border-bottom: 1px solid #97979716;
            overflow: hidden;
            resize: none;
            min-height: 28px;
    
            &::placeholder {
                opacity: 1;
                color: #9d9d9d;
            }
        }
        .bio {
            padding: 0;
        }
        .tip {
            padding-top: 15px;
            font-weight: 500;
            line-height: 18px;
            color: #9d9d9d;
        }
        .error-text {
            font-size: 9px;
            padding: 0px 10px;
            color: red;
        }
    }
}
