.add-product-final-step {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 3;

    .input-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background-color: rgba(#000000, 0.3);

        .input-modal {
            position: absolute;
            z-index: 3;
           
            top: 24vh;
            left: 0;
            margin: auto;
            width: 100vw;
            height: 76vh;
            background: white;
            padding: 14px 14px;

            .description-input {
                resize: none;
                width: calc(100vw - 30px);
                height: calc(76vh - 30px);
                border: none;
                outline: none;
                font-family: 'SF Pro Light', sans-serif;
                color: #9D9D9D;
                line-height: 16px;
                font-size: 13px;
                color: #000000;
                caret-color: #fc6292;
            }
            .description-input::placeholder {
                color: rgba(#000000, 0.3);
            }
        }
    }
    .add-product-final-step-container {
        margin-top: 16px;
        

        .chosen-image-final {
            position: absolute;
            top: calc(16px + 47px);
            right: 16px;
            width: 15vh;
            height: 15vh;
            border-radius: 10%;
        }
        
        .tags-input {
            align-items: flex-start;
            flex-wrap: wrap;
            overflow-y: auto;
            height: 17vh;
            width: 63%;
            padding-left: 5vw;
            padding-bottom: 2vh;
            line-height: 16px;

            .input-before {
                position: relative;
                display: inline-block;
                opacity: 0.3;
                top: -17px;
                left: -1px;
            }
            textarea {
                // min-width: calc((90vw - 15vh) / 2);
                border: none;
                outline: none;
                font-size: 13px;
                font-family: 'SF Pro Light', sans-serif;
                color: #9D9D9D;
                padding: 4px 0 0 0;
                caret-color: #fc6292;
                resize: none;
            }
        }
        
        #tags {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            margin: 4px 0 0 0;
        }
        
        .tag {
            width: auto;
            display: flex;
            height: 23px;
            align-items: center;
            justify-content: center;
            padding: 0 8px;
            font-size: 13px;
            list-style: none;
            border: 1px solid #9797974f;
            border-radius: 30px;
            margin: 0 8px 8px 0;
            background: white;

            strong {
                opacity: 0.3;
            }

            .tag-title {
                font-family: 'SF Pro Light', sans-serif;
            }
        }

        p {
            font-size: 11px;
            margin-bottom: 6px;
            margin-left: 6vw;
            margin-top: 4px;
        }
        .suggested-tags-conteiner {
            height: 85vw;
            border-top: 1px solid #9797971f;
            overflow-y: scroll;
            margin-bottom: 5vh;
            font-size: 13px;

            .top-header {
                padding-top: 15px;
                font-family: 'SF Pro Regular', sans-serif;
            }

            .suggested-tag {
                color: #9D9D9D;
                border: none;
                outline: none;
                background: none;
                margin-left: 10vw;
                margin-right: 60vw;
                margin: 9px 60vw 17px 10vw;
                font-family: 'SF Pro Light', sans-serif;
            }
            .used-tag {
                border-radius: 20px;
                outline: none;
                background: none;
                font-family: 'SF Pro Light', sans-serif;
                border: 1px solid #9797971f;
                margin: 9px 60vw 17px 10vw;//2vh 60vw calc(2vh - 1px) 10vw;
            }
        }
        .description-input {
            padding: 18px 20px;
            height: 30vh;
            width: 100%;
            font-size: 13px;
            -webkit-appearance: none;
            border-radius: 0;
            border: none;
            border-top: 1px solid #97979716;
            font-family: 'SF Pro Light', sans-serif;
            color: #000000;
            outline: none;
            resize: none;
        }
        .drafts, .share {
            border: none;
            outline: none;
            background: none;
            margin: 0 auto;
            margin-top: 5vh;
            border-radius: 30px;
            width: 40vw;
            height: 40px;
            font-weight: 700;
        }
        .drafts {
            border: 1px solid rgba(#000000, 0.3);
            margin-right: 10px;
        }
        .share {
            color: white;
            background: rgba(#000000, 0.2);
            margin-right: 10px;
        }
        .colored {
            background: linear-gradient(241deg, #FC5899 0%, #FBBB5D 100%);
        }
        .buttons {
            text-align: center;
        }
    }
    .automatically-share-label {
        font-family: 'SF Pro Light', sans-serif;
    }
}