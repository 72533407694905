.header {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, auto));
    align-items: center;
    font-size: 17px;
    font-family: 'SF Pro Medium', sans-serif;
    height: 47px;
    padding: 0 23px;
    .Button {
        display: inline-flex;
        border: none;
        padding: 0;
    }

    .header-title {
        display: inline-flex;
        justify-content: center;
    }

    .back {
        justify-content: flex-start;
    }

    .next,
    .save,
    .done  {
        justify-content: flex-end;
        color: #fc6292;
    }

    .cancel {
        opacity: 0.5;
    }
    
    .star-image {
        display: inline-flex;
        justify-content: center;
        padding-bottom: 2px;
        margin: 0 3px 0 2px;
        width: 25px;
        height: 25px;
    }
}
.buttonImage,
.close-button {
    border: none;
    outline: none;
    background: none;
    position: absolute;
    top: 8px;
    right: 12px;
    z-index: 2;
    padding: 0;
}

.buttonImage {
    right: 30px;
}

.single-header {
    grid-template-columns: 1fr;
}

.bordered-header {
    border-bottom: 1px solid #97979716;
}
.margin-header {
    margin-bottom: 25px;
}
.image-cropper-header {
    position: absolute;
    background: white;
    z-index: 1;
    width: 100%;
}
.small-padding {
    padding: 0 4vw;
}